<template>
    <div>
        <el-form :inline="true" v-if="hasAuth('sys:customerservice:add')">
            <el-form-item>
                <el-button type="primary" @click="saveButton(null)" >新增</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="serviceName" align="center" label="客服名称" >
            </el-table-column>

            <el-table-column prop="servicePhone" align="center" label="客服电话">
            </el-table-column>
            <el-table-column prop="identity" align="center" label="客服类别" >
                <template slot-scope="{row}">
                    <span v-if="row.identity == 1">货主</span>
                    <span v-if="row.identity == 2">司机</span>
                </template>
            </el-table-column>
            <el-table-column prop="icon" align="center" width="300" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain
                        @click="saveButton(scope.row.serviceId)" v-if="hasAuth('sys:customerservice:update')">编辑</el-button>
                    <template v-if="hasAuth('sys:customerservice:delete')"> 
                        <el-popconfirm title="确定要删除该条数据吗？" @confirm="delHandle(scope.row.serviceId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <saveCustomerservice ref="saveCustomerservice" v-if="saveCustomerservicevisible" @refreshDataList="getCustomerservice"></saveCustomerservice>
    </div>
</template>

<script>
import saveCustomerservice from "./saveCustomerservice";
export default {
    name: "VehicletypeList",
    components: {
        saveCustomerservice
    },
    data() {
        return {
            tableData: [],
            saveCustomerservicevisible: false,
        }
    },
    created() {
        this.getCustomerservice();
    },
    methods: {
        getCustomerservice() {
            this.$axios.post("/admin/customerservice/getcustomerserviceList").then(res => {
                this.tableData = res.data
            })
        },
        delHandle(id) {
            this.$axios.post("/admin/customerservice/deteleCustomerservice", { serviceId: id }).then(res => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.getCustomerservice()
                    }
                });
            })
        },
        saveButton(serviceId) {
            this.saveCustomerservicevisible = true;
            this.$nextTick(() => {
                this.$refs.saveCustomerservice.init(serviceId);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>

